import React, { useState } from "react";
import Logo from "assets/logo-navbar.svg";
import Profile from "assets/icons/profile.svg";
import Cart from "assets/icons/cart.svg";

export default function Navbar() {
	const token = localStorage.getItem("token");
	const [navbar, setNavbar] = useState(false);

	// return (
	// 	<header className="flex justify-between items-center p-7 font-inter">
	// 		<div>
	// 			<a href="/" className="no-underline">
	// 				<img src={Logo} />
	// 			</a>
	// 		</div>
	// 		<ul className="flex items-center">
	//
	// 		</ul>
	// 	</header>
	// );
	return (
		<nav className={`w-full md:bg-none font-inter ${navbar && "bg-[#96AFB2]"}`}>
			<div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8 md:py-4">
				<div>
					<div className="flex items-center justify-between py-3 md:py-5 md:block">
						<a href="/">
							<img src={Logo} />
						</a>
						<div className="md:hidden">
							<button
								className="p-2 text-gray-700 rounded-md outline-none"
								onClick={() => setNavbar(!navbar)}
							>
								{navbar ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="w-6 h-6"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="w-6 h-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M4 6h16M4 12h16M4 18h16"
										/>
									</svg>
								)}
							</button>
						</div>
					</div>
				</div>
				<div>
					<div
						className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
							navbar ? "block" : "hidden"
						}`}
					>
						<ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
							<li>
								<a
									className={[
										"text-white text-base px-6 md:py-3 font-normal",
									].join(" ")}
									href="/tentang-kami"
								>
									Tentang Kami
								</a>
							</li>

							<li>
								<a
									className={[
										"text-white text-base px-6 py-3 font-normal",
									].join(" ")}
									href="/assesment"
								>
									Assessment
								</a>
							</li>
							<li>
								<a
									className={[
										"text-white text-base px-6 py-3 font-normal",
									].join(" ")}
									href="/product"
								>
									Produk
								</a>
							</li>
							<li>
								<a
									className={[
										"text-white text-base px-6 py-3 font-normal",
									].join(" ")}
									href="/artikel"
								>
									Artikel
								</a>
							</li>
							<li className="mr-[40px]">
								<a
									className={[
										"text-white text-base px-6 py-3 font-normal",
									].join(" ")}
									href="/faq"
								>
									FAQ
								</a>
							</li>
							<li className={token ? "" : "flex"}>
								{!token ? (
									<a
										href="/auth/login"
										className="bg-[#1B3E66] transition-all duration-200 text-white hover:text-white text-md px-4 py-2 font-medium ml-6 rounded-2xl font-extrabold"
									>
										LOGIN
									</a>
								) : (
									<>
										<a href="/profile" className="mr-[8px]">
											<img src={Profile} />
										</a>
										<a href="/cart">
											<img src={Cart} />
										</a>
									</>
								)}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
}
